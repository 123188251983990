import { RocketOutlined, TeamOutlined, ApiOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";

import LydiaReportPNG from "../assets/LydiaReport.png";
import LydiaDrawerPNG from "../assets/LydiaDrawer.png";
import LydiaOverviewMP4 from "../assets/Claims Cropped.mp4";
import hipaaPNG from "../assets/HIPAA.png";

import MayoPNG from "../assets/mayo.png";
import StanfordPNG from "../assets/stanford.png";
import StanfordMedicalPNG from "../assets/stanfordmed.png";
import HMSPNG from "../assets/hms.png";
import CaltechPNG from "../assets/caltech.png";
import UCLAPNG from "../assets/ucla.png";

import "../App.css";

function LandingScreenMobile() {
   const [currentWord, setCurrentWord] = useState("Claims");
   const words = ["Claims", "Referrals", "Faxes", "Eligibility", "Prior Auths"];

   useEffect(() => {
      const intervalId = setInterval(() => {
         setCurrentWord((prevWord) => {
            const currentIndex = words.indexOf(prevWord);
            return words[(currentIndex + 1) % words.length];
         });
      }, 2000);

      return () => clearInterval(intervalId);
   }, []);

   return (
      <div style={{ backgroundColor: "#F6F4F6", width: "100%" }}>
         {/* Header Section */}
         <div
            style={{
               backgroundColor: "#2D0A3D",
               width: "100%",
               position: "fixed",
               top: 0,
               zIndex: 1000,
               borderBottom: "1px solid rgba(255,255,255,0.2)",
            }}
         >
            <div style={{ padding: "15px 20px" }}>
               <p
                  style={{
                     marginBlock: "0px",
                     fontWeight: 900,
                     fontSize: "28px",
                     color: "white",
                  }}
               >
                  Lydia
               </p>
            </div>
         </div>

         {/* Hero Section */}
         <div
            style={{
               backgroundColor: "#2D0A3D",
               width: "100%",
               padding: "80px 20px 40px",
               textAlign: "center",
            }}
         >
            <h1
               style={{
                  fontWeight: "700",
                  fontSize: "40px",
                  color: "white",
                  lineHeight: "1.2",
                  margin: "20px 0",
               }}
            >
               Put Your <span style={{ color: "#8A2BE2" }}>{currentWord}</span>
               <br />
               on Autopilot
            </h1>
            <p
               style={{
                  fontWeight: "400",
                  fontSize: "20px",
                  color: "white",
                  opacity: "0.9",
                  margin: "20px 0",
               }}
            >
               Lydia's AI Medical Assistant works your repetitive back office tasks for you.
            </p>

            <video
               className="HeroSectionImage"
               style={{
                  width: "100%",
                  maxWidth: "500px",
                  height: "auto",
                  margin: "30px 0",
               }}
               autoPlay
               muted
               loop
               playsInline
            >
               <source src={LydiaOverviewMP4} type="video/mp4" />
               Your browser does not support the video tag.
            </video>

            <div style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
               <a
                  href="https://airtable.com/appcBoviT7QYbR8FV/pagXAyTUfX4XNVw8Y/form"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                     backgroundColor: "#8A2BE2",
                     color: "white",
                     padding: "15px 30px",
                     borderRadius: "8px",
                     textDecoration: "none",
                     fontWeight: "500",
                     fontSize: "18px",
                     width: "100%",
                     maxWidth: "300px",
                     textAlign: "center",
                  }}
               >
                  Request a Trial
               </a>
            </div>

            <div style={{ color: "white", fontSize: "14px", margin: "15px 0" }}>
               No start-up costs or commitment.
            </div>

            <div style={{ margin: "30px 0" }}>
               <img src={hipaaPNG} alt="HIPAA Compliant" style={{ height: "60px" }} />
            </div>
         </div>

         {/* Benefits Section */}
         <div
            id="benefits"
            style={{
               padding: "40px 20px",
               backgroundColor: "white",
            }}
         >
            <h2 style={{ textAlign: "center", fontSize: "24px", marginBottom: "30px" }}>
               End-to-End Back Office Automation
            </h2>

            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
               {/* Benefit Cards */}
               {[
                  {
                     icon: <TeamOutlined style={{ fontSize: "28px", color: "#54007A" }} />,
                     title: "10x your team without hiring",
                     description:
                        "Let your staff review AI-completed work instead of manually entering data",
                  },
                  {
                     icon: <RocketOutlined style={{ fontSize: "28px", color: "#54007A" }} />,
                     title: "Process work overnight",
                     description:
                        "Wake up to completed claims, referrals, and prior auths—no backlogs or overtime",
                  },
                  {
                     icon: <ApiOutlined style={{ fontSize: "28px", color: "#54007A" }} />,
                     title: "Easy to use",
                     description:
                        "No need for a big tech overhaul—our AI gets to work directly on your EHR and portals.",
                  },
               ].map((benefit, index) => (
                  <div
                     key={index}
                     style={{
                        padding: "20px",
                        backgroundColor: "white",
                        borderRadius: "12px",
                        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                     }}
                  >
                     <div style={{ marginBottom: "15px" }}>{benefit.icon}</div>
                     <h3 style={{ fontSize: "20px", marginBottom: "10px" }}>{benefit.title}</h3>
                     <p style={{ color: "#666", margin: 0 }}>{benefit.description}</p>
                  </div>
               ))}
            </div>
         </div>

         {/* How it Works Section */}
         <div
            id="howitworks"
            style={{
               backgroundColor: "#fafafa",
               padding: "40px 20px",
            }}
         >
            <h2 style={{ fontSize: "32px", marginBottom: "30px", textAlign: "center" }}>
               AI That Works for Independent Practices
            </h2>

            <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
               {/* Steps */}
               {[
                  {
                     number: "1",
                     title: "White Glove Customization",
                     description:
                        "Show us how you'd like your tasks done once, and we'll make sure the AI replicates that process every time.",
                  },
                  {
                     number: "2",
                     title: "Quality Work with Quality Control",
                     description:
                        "Review daily or weekly reports on your schedule, so you can maintain full oversight.",
                  },
                  {
                     number: "3",
                     title: "Live in Days Not Months",
                     description:
                        "Our process is quick and designed to be easier than onboarding a new employee.",
                  },
               ].map((step, index) => (
                  <div key={index} style={{ display: "flex", gap: "15px" }}>
                     <div
                        style={{
                           backgroundColor: "#FFD700",
                           borderRadius: "50%",
                           width: "35px",
                           height: "35px",
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "center",
                           fontWeight: "bold",
                           flexShrink: 0,
                        }}
                     >
                        {step.number}
                     </div>
                     <div>
                        <h3 style={{ fontSize: "20px", marginBottom: "8px" }}>{step.title}</h3>
                        <p style={{ color: "#666", margin: 0 }}>{step.description}</p>
                     </div>
                  </div>
               ))}
            </div>

            <div style={{ marginTop: "30px" }}>
               <img
                  src={LydiaReportPNG}
                  alt="Lydia Report"
                  style={{
                     width: "100%",
                     borderRadius: "8px",
                     marginBottom: "20px",
                  }}
               />
               <img
                  src={LydiaDrawerPNG}
                  alt="Lydia Drawer"
                  style={{
                     width: "100%",
                     borderRadius: "8px",
                  }}
               />
            </div>
         </div>

         {/* Security Section */}
         <div
            style={{
               background: "linear-gradient(135deg, #2D0A3D 0%, #54007A 100%)",
               padding: "40px 20px",
               color: "white",
               textAlign: "center",
            }}
         >
            <h2 style={{ fontSize: "32px", marginBottom: "20px" }}>Private & Compliant</h2>
            <p style={{ fontSize: "16px", lineHeight: "1.6", marginBottom: "30px" }}>
               We safeguard your data with industry-leading security measures. Our platform is fully
               compliant with HIPAA regulations, ensuring your patient data and proprietary
               information is protected at all times.
            </p>
            <img src={hipaaPNG} alt="HIPAA Compliant" style={{ height: "50px" }} />
         </div>

         {/* Pricing Section */}
         <div id="pricing" style={{ padding: "40px 20px", backgroundColor: "white" }}>
            <h2 style={{ fontSize: "32px", textAlign: "center", marginBottom: "30px" }}>Pricing</h2>

            {/* Pricing Cards */}
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
               {/* Trial Plan */}
               <div
                  style={{
                     padding: "30px",
                     backgroundColor: "#FFF8F0",
                     borderRadius: "12px",
                     border: "1px solid #E0E0E0",
                  }}
               >
                  <h3 style={{ fontSize: "24px", marginBottom: "8px" }}>Trial</h3>
                  <div style={{ fontSize: "36px", fontWeight: "bold", marginBottom: "20px" }}>
                     Free
                  </div>
                  <ul style={{ listStyle: "none", padding: 0, margin: "20px 0" }}>
                     <li style={{ marginBottom: "12px" }}>✓ 30 day free trial</li>
                     <li style={{ marginBottom: "12px" }}>✓ White-glove onboarding</li>
                  </ul>
                  <a
                     href="https://airtable.com/appcBoviT7QYbR8FV/pagXAyTUfX4XNVw8Y/form"
                     target="_blank"
                     rel="noopener noreferrer"
                     style={{
                        display: "block",
                        padding: "12px",
                        backgroundColor: "#8A2BE2",
                        color: "white",
                        textDecoration: "none",
                        borderRadius: "8px",
                        textAlign: "center",
                     }}
                  >
                     Request a Trial
                  </a>
               </div>

               {/* Group Plan */}
               <div
                  style={{
                     padding: "30px",
                     backgroundColor: "#F8F0FF",
                     borderRadius: "12px",
                     border: "1px solid #E0E0E0",
                  }}
               >
                  <h3 style={{ fontSize: "24px", marginBottom: "8px" }}>Group</h3>
                  <div style={{ fontSize: "36px", fontWeight: "bold", marginBottom: "20px" }}>
                     Custom Price
                  </div>
                  <ul style={{ listStyle: "none", padding: 0, margin: "20px 0" }}>
                     <li style={{ marginBottom: "12px" }}>
                        ✓ Pay per task completed (starting at $1/task)
                     </li>
                     <li style={{ marginBottom: "12px" }}>✓ No minimum commitment or setup fees</li>
                  </ul>
                  <a
                     href="https://airtable.com/appcBoviT7QYbR8FV/pagXAyTUfX4XNVw8Y/form"
                     target="_blank"
                     rel="noopener noreferrer"
                     style={{
                        display: "block",
                        padding: "12px",
                        backgroundColor: "#8A2BE2",
                        color: "white",
                        textDecoration: "none",
                        borderRadius: "8px",
                        textAlign: "center",
                     }}
                  >
                     Contact Us
                  </a>
               </div>
            </div>
         </div>

         {/* FAQ Section */}
         <div
            id="faq"
            style={{
               backgroundColor: "#fafafa",
               padding: "40px 20px",
            }}
         >
            <h2 style={{ fontSize: "32px", textAlign: "center", marginBottom: "30px" }}>FAQ</h2>

            <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
               {[
                  {
                     question: "What is Lydia?",
                     answer:
                        "Lydia is a platform that helps healthcare practices streamline their back office operations. It uses AI to automate typical medical assistant tasks, from eligibility checking and claim submission to referrals and fax data entry.",
                  },
                  {
                     question: "Is Lydia HIPAA-compliant?",
                     answer:
                        "Yes, Lydia is HIPAA-compliant with a BAA in place. We use enterprise-grade security and access controls to ensure your data is private and secure.",
                  },
                  {
                     question: "What EHRs does Lydia work with?",
                     answer:
                        "Lydia works with all EHRs and insurance portals that have a browser-based interface (eClinicalWorks, Athena, NextGen, etc.). We can install our software into your systems within 24 hours of receiving log-ins.",
                  },
                  {
                     question:
                        "How much time will Lydia take me to set up? Who will be my point of contact?",
                     answer:
                        "You will work with a dedicated team member who will be your point of contact. Initial set up will likely take a 1-2 hour meeting with your team, followed by short communications to make any requested changes. Afterwards, you might only need to meet once a month or less.",
                  },
               ].map((item, index) => (
                  <details
                     key={index}
                     style={{
                        backgroundColor: "white",
                        padding: "15px",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                     }}
                  >
                     <summary
                        style={{
                           fontSize: "18px",
                           fontWeight: "600",
                           cursor: "pointer",
                        }}
                     >
                        {item.question}
                     </summary>
                     <p style={{ marginTop: "15px", color: "#666" }}>{item.answer}</p>
                  </details>
               ))}
            </div>
         </div>

         {/* Team Section */}
         <div style={{ backgroundColor: "white", padding: "40px 20px", textAlign: "center" }}>
            <p style={{ fontSize: "24px", fontWeight: "700", marginBottom: "30px" }}>
               Built by clinicians and engineers from leading institutions.
            </p>
            <div
               style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  gap: "20px",
               }}
            >
               {[
                  { src: StanfordPNG, alt: "Stanford" },
                  { src: StanfordMedicalPNG, alt: "Stanford Medical" },
                  { src: HMSPNG, alt: "Harvard Medical School" },
                  { src: MayoPNG, alt: "Mayo Clinic" },
                  { src: UCLAPNG, alt: "UCLA" },
                  { src: CaltechPNG, alt: "Caltech" },
               ].map((logo, index) => (
                  <img
                     key={index}
                     src={logo.src}
                     alt={logo.alt}
                     style={{ height: "40px", width: "auto" }}
                  />
               ))}
            </div>
         </div>

         {/* Footer */}
         <div
            style={{
               backgroundColor: "#2D0A3D",
               padding: "30px 20px",
               color: "white",
               textAlign: "center",
            }}
         >
            <img
               src={hipaaPNG}
               alt="HIPAA Compliant"
               style={{ height: "80px", marginBottom: "20px" }}
            />
            <p style={{ fontSize: "18px", opacity: 0.9, margin: "20px 0" }}>
               Bringing AI-Powered Services to Community Practices
            </p>
            <p style={{ fontSize: "12px", opacity: 0.5 }}>
               Copyright © 2024 Lydia Technologies. All rights reserved.
            </p>
         </div>
      </div>
   );
}

export default LandingScreenMobile;
