import { RocketOutlined, TeamOutlined, ApiOutlined, DollarOutlined } from "@ant-design/icons";

import LydiaReportPNG from "../assets/LydiaReport.png";
import LydiaDrawerPNG from "../assets/LydiaDrawer.png";
import LydiaOverviewMP4 from "../assets/Claims Cropped.mp4";
import hipaaPNG from "../assets/HIPAA.png";

import MayoPNG from "../assets/mayo.png";
import StanfordPNG from "../assets/stanford.png";
import StanfordMedicalPNG from "../assets/stanfordmed.png";
import HMSPNG from "../assets/hms.png";
import CaltechPNG from "../assets/caltech.png";
import UCLAPNG from "../assets/ucla.png";

import "../App.css";
import { useState, useEffect } from "react";

function LandingScreen() {
   const [currentWord, setCurrentWord] = useState("Claims");
   const words = ["Claims", "Referrals", "Faxes", "Eligibility", "Prior Auths"];

   useEffect(() => {
      const intervalId = setInterval(() => {
         setCurrentWord((prevWord) => {
            const currentIndex = words.indexOf(prevWord);
            return words[(currentIndex + 1) % words.length];
         });
      }, 2000);

      return () => clearInterval(intervalId);
   }, []);

   return (
      <div
         style={{
            backgroundColor: "#F6F4F6",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
         }}
      >
         {/* Header and Hero Section */}
         <div
            style={{
               backgroundColor: "#2D0A3D",
               width: "100%",
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
               paddingTop: "80px",
            }}
         >
            {/* Header Navigation */}
            <div
               style={{
                  width: "100%",
                  borderBottom: "1px solid rgba(255,255,255,0.2)",
                  position: "fixed",
                  top: 0,
                  zIndex: 1000,
                  backgroundColor: "#2D0A3D",
               }}
            >
               <div
                  style={{
                     display: "flex",
                     justifyContent: "space-between",
                     alignItems: "center",
                     padding: "18px 18px",
                     width: "100%",
                     maxWidth: "1600px",
                     margin: "0 auto",
                  }}
               >
                  <p
                     style={{
                        marginBlock: "0px",
                        fontWeight: 900,
                        fontSize: "36px",
                        color: "white",
                        padding: "0",
                     }}
                  >
                     Lydia
                  </p>
                  <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                     <a
                        href="#benefits"
                        style={{
                           color: "white",
                           textDecoration: "none",
                           fontSize: "16px",
                        }}
                     >
                        Features
                     </a>
                     <a
                        href="#howitworks"
                        style={{
                           color: "white",
                           textDecoration: "none",
                           fontSize: "16px",
                        }}
                     >
                        How it Works
                     </a>
                     <a
                        href="#pricing"
                        style={{
                           color: "white",
                           textDecoration: "none",
                           fontSize: "16px",
                        }}
                     >
                        Pricing
                     </a>
                     <a
                        href="#faq"
                        style={{
                           color: "white",
                           textDecoration: "none",
                           fontSize: "16px",
                        }}
                     >
                        FAQ
                     </a>
                     <a
                        className="LoginButton"
                        href="https://lydia-20cda.web.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                           padding: "12px 24px",
                           backgroundColor: "white",
                           color: "#2D0A3D",
                           textDecoration: "none",
                           borderRadius: "8px",
                           fontWeight: "500",
                           fontSize: "16px",
                        }}
                     >
                        Log In
                     </a>
                     <a
                        className="TrialButton"
                        href="https://airtable.com/appcBoviT7QYbR8FV/pagXAyTUfX4XNVw8Y/form"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                           padding: "12px 24px",
                           backgroundColor: "#8A2BE2",
                           color: "white",
                           textDecoration: "none",
                           borderRadius: "8px",
                           fontWeight: "500",
                           fontSize: "16px",
                        }}
                     >
                        Request a Trial
                     </a>
                  </div>
               </div>
            </div>
            {/* Hero Section */}
            <div
               style={{
                  maxWidth: "1500px",
                  width: "100%",
                  padding: "0 5%",
               }}
            >
               <div
                  style={{
                     display: "flex",
                     alignItems: "flex-start",
                     justifyContent: "space-between",
                  }}
               >
                  <div
                     style={{
                        width: "100%",
                     }}
                  >
                     <h1
                        style={{
                           fontWeight: "700",
                           fontSize: "58px",
                           color: "white",
                           lineHeight: "1",
                           marginBottom: "0",
                        }}
                     >
                        Put Your <span style={{ color: "#8A2BE2" }}>{currentWord}</span>
                        <br />
                        on Autopilot
                     </h1>
                     <p
                        style={{
                           marginTop: "64px",
                           fontWeight: "400",
                           fontSize: "24px",
                           color: "white",
                           opacity: "0.9",
                           width: "80%",
                        }}
                     >
                        Lydia's AI Medical Assistant works your repetitive back office tasks for
                        you.
                     </p>
                     <div
                        style={{
                           display: "flex",
                           marginTop: "32px",
                           gap: "20px",
                           alignItems: "center",
                        }}
                     >
                        <a
                           className="ContactButton"
                           href="https://airtable.com/appcBoviT7QYbR8FV/pagXAyTUfX4XNVw8Y/form"
                           target="_blank"
                           style={{
                              backgroundColor: "#8A2BE2", // Changed background color to match theme
                              color: "white", // Changed text color for better contrast
                              padding: "14px 10px", // Adjusted padding for a more substantial button
                              borderRadius: "10px", // Made the button more rounded
                           }}
                        >
                           Request a Trial
                        </a>
                     </div>
                     <div style={{ color: "white", fontSize: "12px", marginTop: "20px" }}>
                        No start-up costs or commitment.
                     </div>
                     <div
                        style={{
                           display: "flex",
                           alignItems: "center",
                           marginTop: "40px",
                           marginBottom: "20px",
                        }}
                     >
                        <img src={hipaaPNG} alt="HIPAA Compliant" style={{ height: "80px" }} />
                     </div>
                  </div>
                  <div>
                     <video
                        className="HeroSectionImage"
                        alt="Document Management Interface"
                        style={{
                           width: "70%",
                           height: "auto",
                           paddingLeft: "30%",
                           paddingRight: "20%",
                           paddingTop: "60px",
                        }}
                        autoPlay
                        muted
                        loop
                        playsInline
                     >
                        <source src={LydiaOverviewMP4} type="video/mp4" />
                        Your browser does not support the video tag.
                     </video>
                     <p
                        style={{
                           color: "rgba(255, 255, 255, 0.7)",
                           fontSize: "14px",
                           textAlign: "center",
                           marginTop: "12px",
                           fontStyle: "italic",
                        }}
                     >
                        Watch Lydia automate claim processing
                     </p>
                  </div>
               </div>
            </div>
         </div>
         {/* Benefits Section */}
         <div
            id="benefits"
            style={{
               display: "flex",
               flexDirection: "column",
               justifyContent: "center",
               alignItems: "center",
               padding: "8vh 5%",
               backgroundColor: "white",
               width: "100%",
            }}
            className="fadein"
         >
            <p
               style={{
                  marginBlock: "0px",
                  fontWeight: "700",
                  fontSize: "24px",
                  marginBottom: "40px",
                  textAlign: "center",
               }}
            >
               End-to-End Back Office Automation
            </p>
            <div
               style={{
                  maxWidth: "1200px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "24px",
                  justifyContent: "center",
                  flexWrap: "wrap",
               }}
            >
               <div
                  style={{
                     flex: 1,
                     minWidth: "250px",
                     backgroundColor: "white",
                     padding: "24px",
                     borderRadius: "12px",
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "center",
                     textAlign: "center",
                  }}
               >
                  <TeamOutlined
                     style={{ fontSize: "32px", color: "#54007A", marginBottom: "16px" }}
                  />
                  <h3 style={{ fontSize: "20px", marginBottom: "8px" }}>
                     10x your team without hiring
                  </h3>
                  <p style={{ color: "#666", fontSize: "16px", margin: 0 }}>
                     Let your staff review AI-completed work instead of manually entering data
                  </p>
               </div>

               <div
                  style={{
                     flex: 1,
                     minWidth: "250px",
                     backgroundColor: "white",
                     padding: "24px",
                     borderRadius: "12px",
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "center",
                     textAlign: "center",
                  }}
               >
                  <RocketOutlined
                     style={{ fontSize: "32px", color: "#54007A", marginBottom: "16px" }}
                  />
                  <h3 style={{ fontSize: "20px", marginBottom: "8px" }}>Process work overnight</h3>
                  <p style={{ color: "#666", fontSize: "16px", margin: 0 }}>
                     Wake up to completed claims, referrals, and prior auths—no backlogs or overtime
                  </p>
               </div>

               <div
                  style={{
                     flex: 1,
                     minWidth: "250px",
                     backgroundColor: "white",
                     padding: "24px",
                     borderRadius: "12px",
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "center",
                     textAlign: "center",
                  }}
               >
                  <ApiOutlined
                     style={{ fontSize: "32px", color: "#54007A", marginBottom: "16px" }}
                  />
                  <h3 style={{ fontSize: "20px", marginBottom: "8px" }}>Easy to use</h3>
                  <p style={{ color: "#666", fontSize: "16px", margin: 0 }}>
                     No need for a big tech overhaul—our AI gets to work directly on your EHR and
                     portals.
                  </p>
               </div>
            </div>
         </div>
         {/* How it works Section */}
         <div
            id="howitworks"
            style={{
               backgroundColor: "#fafafa",
               padding: "2vh 5%",
               width: "100vw",
            }}
         >
            <div
               style={{
                  maxWidth: "70vw",
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0px",
               }}
            >
               <div style={{ display: "flex", gap: "40px", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                     <h2 style={{ fontSize: "36px", fontWeight: "bold", marginBottom: "30px" }}>
                        AI That Works for Independent Practices
                     </h2>
                     <div style={{ display: "flex", gap: "0px", flexDirection: "column" }}>
                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                           <div
                              style={{
                                 backgroundColor: "#FFD700",
                                 borderRadius: "50%",
                                 width: "40px",
                                 height: "40px",
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 fontWeight: "bold",
                                 flexShrink: 0,
                              }}
                           >
                              1
                           </div>
                           <div>
                              <h3
                                 style={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                 }}
                              >
                                 White Glove Customization{" "}
                              </h3>
                              <p style={{ color: "#666" }}>
                                 Show us how you'd like your tasks done once, and we'll make sure
                                 the AI replicates that process every time. Let our team handle the
                                 heavy lifting.
                              </p>
                           </div>
                        </div>

                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                           <div
                              style={{
                                 backgroundColor: "#FFD700",
                                 borderRadius: "50%",
                                 width: "40px",
                                 height: "40px",
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 fontWeight: "bold",
                                 flexShrink: 0,
                              }}
                           >
                              2
                           </div>
                           <div>
                              <h3
                                 style={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                 }}
                              >
                                 Quality Work with Quality Control
                              </h3>
                              <p style={{ color: "#666" }}>
                                 Review daily or weekly reports on your schedule, so you can
                                 maintain full oversight and follow-up at a glance.
                              </p>
                           </div>
                        </div>

                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                           <div
                              style={{
                                 backgroundColor: "#FFD700",
                                 borderRadius: "50%",
                                 width: "40px",
                                 height: "40px",
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 fontWeight: "bold",
                                 flexShrink: 0,
                              }}
                           >
                              3
                           </div>
                           <div>
                              <h3
                                 style={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                 }}
                              >
                                 Live in Days Not Months
                              </h3>
                              <p style={{ color: "#666" }}>
                                 Our process is quick and designed to be easier than onboarding a
                                 new employee.
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div style={{ flex: 1 }}>
                     <img
                        src={LydiaReportPNG}
                        alt="Lydia Deployment Process"
                        style={{
                           width: "100%",
                           height: "auto",
                           borderRadius: "10px",
                           boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        }}
                     />
                     <img
                        src={LydiaDrawerPNG}
                        alt="Lydia Deployment Process"
                        style={{
                           width: "100%",
                           height: "auto",
                           borderRadius: "10px",
                           boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        }}
                     />
                  </div>
               </div>
            </div>
         </div>
         {/* Security Section - Place before Pricing */}
         <div
            style={{
               width: "100%",
               background: "linear-gradient(135deg, #2D0A3D 0%, #54007A 100%)",
               padding: "80px 5%",
               color: "white",
               textAlign: "center",
            }}
         >
            <div
               style={{
                  maxWidth: "1200px",
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
               }}
            >
               <h2
                  style={{
                     fontSize: "48px",
                     fontWeight: "bold",
                     marginBottom: "24px",
                     color: "white",
                  }}
               >
                  Private & Compliant
               </h2>
               <p
                  style={{
                     fontSize: "18px",
                     maxWidth: "800px",
                     lineHeight: "1.6",
                     marginBottom: "40px",
                     color: "rgba(255, 255, 255, 0.9)",
                  }}
               >
                  We safeguard your data with industry-leading security measures. Our platform is
                  fully compliant with HIPAA regulations, ensuring your patient data and proprietary
                  information is protected at all times. Your data will never be sold to third
                  parties or used to train AI.
               </p>
               <div
                  style={{
                     display: "flex",
                     gap: "20px",
                     justifyContent: "center",
                     alignItems: "center",
                     padding: "20px",
                     backgroundColor: "rgba(255, 255, 255, 0.1)",
                     borderRadius: "16px",
                  }}
               >
                  <img src={hipaaPNG} alt="HIPAA Compliant" style={{ height: "60px" }} />
               </div>
            </div>
         </div>
         {/* Pricing Section */}
         <div
            id="pricing"
            style={{
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
               padding: "40px 5% 0",
               backgroundColor: "white",
               width: "100%",
            }}
         >
            <h2 style={{ fontSize: "48px", marginBottom: "60px", textAlign: "center" }}>Pricing</h2>
            <div
               style={{
                  display: "flex",
                  gap: "24px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  maxWidth: "1200px",
                  width: "100%",
               }}
            >
               {/* Trial Plan */}
               <div
                  style={{
                     flex: "1",
                     minWidth: "300px",
                     maxWidth: "380px",
                     padding: "32px",
                     borderRadius: "16px",
                     backgroundColor: "#FFF8F0",
                     border: "1px solid #E0E0E0",
                  }}
               >
                  <h3 style={{ fontSize: "24px", marginBottom: "8px" }}>Trial</h3>
                  <div style={{ fontSize: "42px", fontWeight: "bold", marginBottom: "24px" }}>
                     Free
                  </div>
                  <ul style={{ listStyle: "none", padding: 0, margin: "24px 0" }}>
                     <li style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                        ✓ <span style={{ marginLeft: "12px" }}>30 day free trial</span>
                     </li>
                     <li style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                        ✓ <span style={{ marginLeft: "12px" }}>White-glove onboarding</span>
                     </li>
                  </ul>
                  <a
                     href="https://airtable.com/appcBoviT7QYbR8FV/pagXAyTUfX4XNVw8Y/form"
                     target="_blank"
                     rel="noopener noreferrer"
                     style={{
                        display: "inline-block",
                        padding: "12px",
                        backgroundColor: "#8A2BE2",
                        color: "white",
                        textDecoration: "none",
                        borderRadius: "8px",
                        width: "94%",
                        textAlign: "center",
                        marginBottom: "16px",
                     }}
                  >
                     Request a Trial
                  </a>
               </div>

               {/* Group Plan */}
               <div
                  style={{
                     flex: "1",
                     minWidth: "300px",
                     maxWidth: "380px",
                     padding: "32px",
                     borderRadius: "16px",
                     backgroundColor: "#F8F0FF",
                     border: "1px solid #E0E0E0",
                  }}
               >
                  <h3 style={{ fontSize: "24px", marginBottom: "8px" }}>Group</h3>
                  <div style={{ fontSize: "42px", fontWeight: "bold", marginBottom: "24px" }}>
                     Custom Price
                  </div>
                  <ul style={{ listStyle: "none", padding: 0, margin: "24px 0" }}>
                     <li style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                        ✓{" "}
                        <span style={{ marginLeft: "12px" }}>
                           Pay per task completed (starting at $1/task)
                        </span>
                     </li>
                     <li style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                        ✓{" "}
                        <span style={{ marginLeft: "12px" }}>
                           No minimum commitment or setup fees
                        </span>
                     </li>
                  </ul>
                  <a
                     href="https://airtable.com/appcBoviT7QYbR8FV/pagXAyTUfX4XNVw8Y/form"
                     target="_blank"
                     rel="noopener noreferrer"
                     style={{
                        display: "inline-block",
                        padding: "12px",
                        backgroundColor: "#8A2BE2",
                        color: "white",
                        textDecoration: "none",
                        borderRadius: "8px",
                        width: "94%",
                        textAlign: "center",
                        marginBottom: "16px",
                     }}
                  >
                     Contact Us
                  </a>
               </div>
            </div>
            <p
               style={{
                  textAlign: "center",
                  maxWidth: "800px",
                  margin: "40px 0",
                  color: "#333",
               }}
            >
               We're dedicated to making Lydia accessible to patients and practices of all resource
               levels. If you work with low-resource populations, please{" "}
               <a
                  href="mailto:ryanhan@cs.stanford.edu"
                  style={{ color: "#8A2BE2", textDecoration: "none" }}
               >
                  contact us
               </a>{" "}
               for a discount.
            </p>
         </div>
         {/* FAQ Section */}
         <div
            id="faq"
            style={{
               display: "flex",
               flexDirection: "column",
               justifyContent: "center",
               alignItems: "center",
               backgroundColor: "#fafafa",
               width: "100%",
               padding: "40px 5%",
            }}
         >
            <h2
               style={{
                  fontSize: "48px",
                  fontWeight: "bold",
                  color: "#1B4332",
                  textAlign: "center",
                  marginTop: 0,
               }}
            >
               FAQ
            </h2>
            <div style={{ maxWidth: "800px", width: "60%", marginBottom: "2vh" }}>
               <details
                  style={{
                     marginBottom: "20px",
                     padding: "20px",
                     borderBottom: "1px solid #e0e0e0",
                  }}
               >
                  <summary
                     style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#1B4332",
                        cursor: "pointer",
                     }}
                  >
                     What is Lydia?
                  </summary>
                  <p
                     style={{
                        marginTop: "20px",
                        marginLeft: "24px",
                        fontSize: "20px",
                        color: "#666",
                        lineHeight: "1.6",
                     }}
                  >
                     Lydia is a platform that helps healthcare practices streamline their back
                     office operations. It uses AI to automate typical medical assistant tasks, from
                     eligibility checking and claim submission to referrals and fax data entry.
                  </p>
               </details>

               <details
                  style={{
                     marginBottom: "20px",
                     padding: "20px",
                     borderBottom: "1px solid #e0e0e0",
                  }}
               >
                  <summary
                     style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#1B4332",
                        cursor: "pointer",
                     }}
                  >
                     Is Lydia HIPAA-compliant?
                  </summary>
                  <p
                     style={{
                        marginTop: "20px",
                        marginLeft: "24px",
                        fontSize: "20px",
                        color: "#666",
                        lineHeight: "1.6",
                     }}
                  >
                     Yes, Lydia is HIPAA-compliant with a BAA in place. We use enterprise-grade
                     security and access controls to ensure your data is private and secure.
                  </p>
               </details>

               <details
                  style={{
                     marginBottom: "20px",
                     padding: "20px",
                     borderBottom: "1px solid #e0e0e0",
                  }}
               >
                  <summary
                     style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#1B4332",
                        cursor: "pointer",
                     }}
                  >
                     What EHRs does Lydia work with?
                  </summary>
                  <p
                     style={{
                        marginTop: "20px",
                        marginLeft: "24px",
                        fontSize: "20px",
                        color: "#666",
                        lineHeight: "1.6",
                     }}
                  >
                     Lydia works with all EHRs and insurance portals that have a browser-based
                     interface (eClinicalWorks, Athena, NextGen, etc.). We can install our software
                     into your systems within 24 hours of receiving log-ins.
                  </p>
               </details>

               <details
                  style={{
                     marginBottom: "20px",
                     padding: "20px",
                     borderBottom: "1px solid #e0e0e0",
                  }}
               >
                  <summary
                     style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#1B4332",
                        cursor: "pointer",
                     }}
                  >
                     How much time will Lydia take me to set up? Who will be my point of contact?
                  </summary>
                  <p
                     style={{
                        marginTop: "20px",
                        marginLeft: "24px",
                        fontSize: "20px",
                        color: "#666",
                        lineHeight: "1.6",
                     }}
                  >
                     You will work with a dedicated team member who will be your point of contact.
                     Initial set up will likely take a 1-2 hour meeting with your team, followed by
                     short communications to make any requested changes. Afterwards, you might only
                     need to meet once a month or less.
                  </p>
               </details>
            </div>
         </div>
         {/* Team Section - Static Logos */}
         <div
            style={{
               display: "flex",
               flexDirection: "column",
               justifyContent: "center",
               alignItems: "center",
               backgroundColor: "white",
               width: "100%", // Added full width
            }}
         >
            <div
               style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "40px 5%", // Added padding to inner container
               }}
            >
               <p
                  style={{
                     marginBlock: "0px",
                     fontWeight: "700",
                     fontSize: "30px",
                     textAlign: "center",
                     margin: "2vh",
                  }}
               >
                  Built by clinicians and engineers from leading institutions.
               </p>
               <div
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     gap: "40px",
                     flexWrap: "wrap",
                     width: "100%",
                  }}
               >
                  <img src={StanfordPNG} style={{ height: "60px", width: "auto" }} alt="Stanford" />
                  <img
                     src={StanfordMedicalPNG}
                     style={{ height: "60px", width: "auto" }}
                     alt="StanfordMed"
                  />
                  <img
                     src={HMSPNG}
                     style={{ height: "60px", width: "auto" }}
                     alt="Harvard Medical School"
                  />
                  <img src={MayoPNG} style={{ height: "60px", width: "auto" }} alt="Mayo Clinic" />
                  <img src={UCLAPNG} style={{ height: "60px", width: "auto" }} alt="UCLA" />
                  <img src={CaltechPNG} style={{ height: "60px", width: "auto" }} alt="Caltech" />
               </div>
            </div>
         </div>
         {/* Footer Section */}
         <div
            style={{
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
               padding: "10px 5%",
               backgroundColor: "#2D0A3D",
               width: "100%",
               color: "white",
            }}
         >
            <div
               style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: "1200px",
               }}
            >
               <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                     <img src={hipaaPNG} alt="HIPAA Compliant" style={{ height: "100px" }} />
                  </div>
               </div>

               <p
                  style={{
                     fontSize: "24px",
                     opacity: 0.9,
                     margin: 0,
                     textAlign: "right",
                     maxWidth: "400px",
                     fontWeight: "300",
                  }}
               >
                  Bringing AI-Powered Services to Community Practices
               </p>
            </div>

            <p
               style={{
                  fontSize: "14px",
                  opacity: 0.5,
                  textAlign: "center",
                  width: "100%",
                  maxWidth: "1200px",
               }}
            >
               Copyright © 2024 Lydia Technologies. All rights reserved.
            </p>
         </div>
      </div>
   );
}

export default LandingScreen;
